// components
import { Translation } from '../Translation/Translation';
import YoutubeVideo from '../Video/youtube/YoutubeVideo';
import { InnerHtml } from '../InnerHtml/inner-html';
import { Lightbox } from './Lightbox';
import { BUTTON_TYPES, Button } from '../Button/Button';
import SocialShare from '../SocialShare/SocialShare';
import { MiVideoContainer } from '../Video/miVideo/MiVideoContainer';
import { CopyToClipboard } from '../copy-to-clipboard/copy-to-clipboard';

// utils
import { isEmpty } from 'utils/is-empty';
import { decodingContent } from 'utils/decodingContent';

interface VideoLightboxViewProps {
  handleClose: () => void;
  initVideo: () => void;
  videoId: string;
  isModal?: boolean;
  channelUrl?: string | null;
  title?: string | null;
  isYoutubeVideo?: boolean;
  text?: string | null;
  hasVideoError?: boolean;
  videoRef?: any;
  videoReady?: boolean;
  videoUrl?: string;
}

function VideoLightboxView({
  videoId,
  handleClose,
  initVideo,
  isModal,
  title = null,
  isYoutubeVideo = false,
  text = null,
  channelUrl = null,
  hasVideoError = false,
  videoRef = null,
  videoReady = false,
  videoUrl,
}: Readonly<VideoLightboxViewProps>) {
  const socialShare = {
    title,
    og_url: channelUrl,
  };

  return (
    <Lightbox onCloseRequest={handleClose} onAfterOpen={initVideo}>
      <div className="c-lightbox__container">
        <div className="c-lightbox__video-title">
          <h4>{decodingContent(title)}</h4>
          <Button
            symbol="close"
            type={BUTTON_TYPES.PLAIN}
            onClick={handleClose}
            className="c-lightbox__close"
            noTracking
          >
            <Translation id="web20_lightbox_close" />
          </Button>
        </div>
        <div className="c-lightbox__video-container">
          {isYoutubeVideo ? (
            <YoutubeVideo id={videoId} />
          ) : (
            videoReady && (
              <MiVideoContainer
                videoRef={videoRef}
                hasError={hasVideoError}
                isModal={isModal}
                className="c-text-video"
                content={{ video: { videoId, title } }}
              />
            )
          )}
        </div>
        {text && (
          <div className="c-lightbox__video-description">
            <InnerHtml as="span" content={text} />
          </div>
        )}

        {!isEmpty(channelUrl) && (
          <SocialShare
            metaData={socialShare}
            types={['facebook', 'twitter', 'pinterest', 'linkedin', 'service-mail']}
            contentType="video"
          />
        )}
        {videoUrl && <CopyToClipboard text={videoUrl} />}
      </div>
    </Lightbox>
  );
}

export default VideoLightboxView;
