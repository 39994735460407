import React from 'react';
import { getStringSpecificId } from '../../../utils/slug';

/**
 * Youtube iframe
 *
 * @param id  Video id
 * @param getDomId  Generate id
 * @returns {*}
 * @constructor
 */

interface YoutubeVideoProps {
  id: string;
}

const YoutubeVideo = ({ id }: Readonly<YoutubeVideoProps>) => (
  <div id={getStringSpecificId('youtube', id)} className="c-youtube-video">
    <iframe title="unique" src={`https://www.youtube.com/embed/${id}`} />
  </div>
);

export default YoutubeVideo;
