import { ICON_COLORS } from '../Icon/Icon';

const SOCIAL_SHARE_DATA = [
  {
    baseUrl: 'https://www.facebook.com/sharer/sharer.php?',
    symbol: 'facebook',
    color: ICON_COLORS.PRIMARY,
  },
  {
    baseUrl: 'https://twitter.com/intent/tweet?',
    symbol: 'twitter',
    color: ICON_COLORS.PRIMARY,
  },
  {
    baseUrl: 'https://pinterest.com/pin/create/button/?',
    symbol: 'pinterest',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'https://www.xing.com/app/user?',
    symbol: 'xing',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'https://www.linkedin.com/shareArticle?',
    symbol: 'linkedin',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'https://www.instagram.com/geberit_de/',
    symbol: 'instagram',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'https://www.youtube.com/geberit',
    symbol: 'youtube',
    color: ICON_COLORS.WHITE,
  },
  {
    baseUrl: 'mailto:?',
    symbol: 'service-mail',
    color: ICON_COLORS.PRIMARY,
  },
];

export default SOCIAL_SHARE_DATA;
