import { useEffect } from 'react';
import { Dialogue } from '@geberit/gdds';

// components
import { MiVideoContainer } from '../../Video/miVideo/MiVideoContainer';

// utils
import { decodingContent } from 'utils/decodingContent';

interface VideoModalContentProps {
  videoRef: any;
  hasVideoError: boolean;
  videoReady: boolean;
  handleClose: () => void;
  initVideo: () => void;
  videoId: string;
  title: string;
}

function VideoModalContent({
  handleClose,
  initVideo,
  videoId,
  title,
  hasVideoError = false,
  videoRef = null,
  videoReady = false,
}: Readonly<VideoModalContentProps>) {
  useEffect(() => {
    initVideo();

    return () => {
      handleClose();
    };
  }, []);

  return (
    videoReady && (
      <Dialogue
        body={
          <MiVideoContainer
            videoRef={videoRef}
            hasError={hasVideoError}
            isModal
            className="c-text-video"
            content={{ video: { videoId } }}
          />
        }
        showCloseBtn
        type="custom"
        size="l"
        titleLabel={decodingContent(title)}
        noHeaderSymbol
        fullscreen
        closeDialogue={handleClose}
        closeOnBackgroundClick
      />
    )
  );
}

export default VideoModalContent;
