import { useSelector } from 'react-redux';

// config & constants
import SOCIAL_SHARE_DATA from './constants';

// components
import SocialShareIcon from './SocialShareIcon';
import { SearchIndexOff } from '../SearchIndex/SearchIndexOff';

// utils
import { baseURLSelector } from 'utils/selectors/globalsSelectors';
import { classNameBuilder } from 'utils/classNameBuilder';

interface SocialShareViewProps {
  types: string[];
  metaData: Record<string, any>;
  hashtags: string[];
  className?: string;
  headline?: React.ReactNode;
  contentType: 'page' | 'video';
}

export default function SocialShareView({
  className,
  hashtags,
  metaData,
  types,
  headline,
  contentType,
}: Readonly<SocialShareViewProps>) {
  const baseURL = useSelector(baseURLSelector);
  const socialSharingTypes = SOCIAL_SHARE_DATA.filter((icon) => types.includes(icon.symbol));

  return (
    <SearchIndexOff>
      <div className={classNameBuilder(className, 'social-share')}>
        {headline && <span className="social-share__name">{headline}</span>}
        <div className="social-share__icons">
          {socialSharingTypes.map((item) => (
            <SocialShareIcon
              key={`${item.symbol}-icon`}
              baseUrl={item.baseUrl}
              url={metaData.url || `${baseURL}${metaData.og_url}`}
              symbol={item.symbol}
              color={item.color}
              hashtags={hashtags}
              title={metaData.title}
              image={metaData.image}
              contentType={contentType}
            />
          ))}
        </div>
      </div>
    </SearchIndexOff>
  );
}
