import { useRef, useEffect, useState, useId } from 'react';

// types
import type { VideoLightboxProps } from 'components/ContentElementsGdds/video-channel/types';

// components
import VideoLightboxView from './VideoLightboxView';
import VideoModalContent from 'components/ContentElementsGdds/Video/video-modal-content';

// utils
import { trackMIVideo } from '../Video/miVideo/trackingActions';
import { useUCConsent } from 'features/cookie-consent';
import { useNord } from 'utils/hooks/use-nord';
import { getStringSpecificId } from 'utils/slug';
import { useTracking } from 'utils/tracking/track';

export default function VideoLightbox({
  videoId,
  isYoutubeVideo,
  onClose,
  title,
  ...props
}: Readonly<VideoLightboxProps>) {
  const [hasVideoError, setHasVideoError] = useState(false);
  const isNord = useNord();
  const [videoReady, setVideoReady] = useState(false);
  const ucConsent = useUCConsent();
  const mounted = useRef(false);
  const videoTrackRef = useRef(0);
  const ga4VideoTrackRef = useRef(0);
  const { trackMI24Video } = useTracking();
  const videoRef = useRef();
  const generatedId = useId();
  const id = getStringSpecificId('video-content', videoId, generatedId);
  let videoPlayerWindowObject;

  useEffect(() => {
    mounted.current = true;

    if (ucConsent.Comfort === true) {
      // check if mi video script is already given
      const mi24Script = document.getElementById('mi24-js-script');
      if (!isYoutubeVideo && !mi24Script) {
        const script = document.createElement('script');
        script.src = `//e.video-cdn.net/v2/embed.js`;
        script.id = 'mi24-js-script';
        script.async = true;
        script.addEventListener('load', () => {
          script.setAttribute('data-loaded', true);
          if (mounted.current) setVideoReady(true);
        });
        document.head.appendChild(script);
      } else if (!isYoutubeVideo && mounted.current) {
        if (mi24Script.getAttribute('data-loaded')) {
          setVideoReady(true);
        } else {
          mi24Script.addEventListener('load', () => {
            if (mounted.current) setVideoReady(true);
          });
        }
      }
    }

    return () => {
      mounted.current = false;
    };
  }, [ucConsent, isYoutubeVideo]);

  /**
   * Close the lightbox
   *
   * @param e
   */
  const handleClose = () => {
    onClose();

    if (typeof window !== 'undefined' && videoPlayerWindowObject && !isYoutubeVideo) {
      window.VideoPlayer.Collection.removePlayerById(id);
      videoPlayerWindowObject = false;
    }
  };

  /**
   * Init mi24 video
   */
  const initVideo = () => {
    const { title } = props;

    if (!isYoutubeVideo) {
      if (window.VideoPlayer && !videoPlayerWindowObject && videoRef.current) {
        videoPlayerWindowObject = true;

        try {
          window.VideoPlayer.Collection.addPlayerById(id, {
            success: (playerApi) => {
              trackMIVideo(videoTrackRef, playerApi, title, videoId);
              trackMI24Video(ga4VideoTrackRef, playerApi, video.title ?? content.title, videoId);
            },
          });
        } catch {
          setHasVideoError(true);
        }
      }
    }
  };

  if (!ucConsent.Comfort) {
    return null;
  }

  if (isNord) {
    return (
      <VideoModalContent
        {...props}
        videoRef={videoRef}
        hasVideoError={hasVideoError}
        videoId={videoId}
        initVideo={initVideo}
        handleClose={handleClose}
        videoReady={videoReady}
        title={props.videoTitle}
      />
    );
  }

  return (
    <VideoLightboxView
      {...props}
      title={title}
      videoRef={videoRef}
      hasVideoError={hasVideoError}
      videoId={videoId}
      isYoutubeVideo={isYoutubeVideo}
      initVideo={initVideo}
      handleClose={handleClose}
      videoReady={videoReady}
    />
  );
}
